import { useToggleState } from '@/shared/hooks/useToggleState';

import { useCreateCertificate } from '../model';

export const useCreateAndOpenCertificate = () => {
  const addNameBottomSheetState = useToggleState()
  const createCertificate = useCreateCertificate();

  const handleSuccessEnter = (guideId: string) => {
    addNameBottomSheetState.handleClose()
    createCertificate.mutate(
      { guideId },
      {
        onSuccess: (certificateResponse) => {
          window.open(certificateResponse.pdf_url, '_blank');
        },
      },
    );
  }

  return {
    handleSuccessEnter, addNameBottomSheetState,
  }
}