import { useGrowthBook } from '@growthbook/growthbook-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { GuideCertificateAPI } from '@/entities/certificates';


export function useCertificateQuery({ guideId }: { guideId: string }) {
  return useQuery({
    queryKey: ['guide-certificate', guideId],
    queryFn: () =>
      GuideCertificateAPI.getCertificate(guideId).then(({ data }) => data),
  });
}

export function useCreateCertificate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['create-certification-mutation'],
    mutationFn: ({ guideId }: { guideId: string }) =>
      GuideCertificateAPI.createCertificate(guideId).then(({ data }) => data),
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({
        queryKey: ['guide-certificate', variables.guideId],
      }),
  });
}

interface UseCertificateListQuery {
  enabled?: boolean;
}
export function useCertificatesListQuery({
  enabled = true,
}: UseCertificateListQuery) {
  const gb = useGrowthBook();
  const arrow = gb?.getAttributes()?.arrow as string ?? 'ai'

  return useQuery({
    enabled,
    queryKey: ['guide-certificates-list', arrow],
    queryFn: () =>
      GuideCertificateAPI.getCertificatesList(arrow).then(
        ({ data }) => data
      ),
  });
}