import { FC, useState } from 'react';

import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { usePatchFullName, usePersonalProfileQuery } from '@/entities/personal-profile/model';
import { UiPrimaryButton } from '@/modules/shared/ui';

import { CertificateAnalytics } from '../lib/analytics';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isEditing: boolean;
  onSuccess: VoidFunction;
  guideId: string;
}

export const EnterNameBottomSheet: FC<Props> = ({
  isOpen, onClose, onSuccess, isEditing, guideId,
}) => {
  const { t } = useTranslation();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const userFullName =
    userPersonalProfile?.data?.personalization_criteria?.full_name?.[0];
  const [fullName, setFullName] = useState<string | undefined>(userFullName);


  const updateFullName = usePatchFullName();

  const onNameSubmit = () => {
    if (!fullName) return;
    CertificateAnalytics.onSubmitEdit({guide_id: guideId, input: fullName, })
    updateFullName.mutate( fullName, {
      onSuccess,
    });
  };

  const loadingButton = t('guides.loading');
  const submitButton = t('guides.submit-button');

  const inputPlaceholder = t('certificate.editNameBottomSheet.placeholder');


  return (
    <BottomSheet
      key="select-categories-bottomsheet"
      expandOnContentDrag
      open={isOpen}
      onDismiss={onClose}
      style={{
        height: '100%',
        zIndex: 1000000,
        position: 'relative',
      }}
    >
      <div className="select-categories px-4 pt-[10px] flex flex-col h-full">
        <div className="select-categories__heading">
          <div>
            <h1 className="select-categories__title text-[24px] mb-2">
              {isEditing ? t('certificate.editNameBottomSheet.title') : t('certificate.addNameBottomSheet.title')}
            </h1>
            <p>
              {isEditing ? t('certificate.editNameBottomSheet.description') : t('certificate.addNameBottomSheet.description')}
            </p>
          </div>
        </div>
        <div className="">
          <TextField
            sx={{
              width: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
            id="name"
            type="text"
            name="name"
            autoComplete="on"
            margin="normal"
            onChange={e => {
              setFullName(e.target.value);
            }}
            placeholder={inputPlaceholder}
            InputLabelProps={{ shrink: true }}
            value={fullName}
          />
        </div>
        <div className="mt-[32px] mb-[42px]">
          <UiPrimaryButton
            className="w-full"
            onClick={onNameSubmit}
            disabled={!fullName}
          >
            {updateFullName.isLoading  ? loadingButton : submitButton}
          </UiPrimaryButton>
        </div>
      </div>
    </BottomSheet>
  );
};
