import { useMemo } from 'react';

import { QueryObserverResult } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  GetCertificateDetailsDto,
  GetOneCertificateDto,
} from '@/entities/certificates';
import { usePersonalProfileQuery } from '@/entities/personal-profile';
import { GuideDetailsType } from '@/modules/guides/entities/guide';
import { GuideProgressByIdType } from '@/modules/guides/entities/guide-progress';
import SeparatorIcon from '@/modules/guides-v2/shared/assets/svg/sepator-icon.svg';
import { UiPrimaryButton } from '@/modules/shared/ui';
import { useToggleState } from '@/shared/hooks/useToggleState';
import {
  CertificateBottomSheet,
  EnterNameBottomSheet,
  useCreateAndOpenCertificate,
  usePollAndOpenCertificate,
} from 'src/features/certificate-open';


import { GuidesV2Analytics } from '../../../shared/analytics';
import './assets/guide-certificate.css';

export const GuideCertificateCard = ({
  guideCertificate,
  progress,
  onRefetch,
}: {
  progress: GuideProgressByIdType;
  guideCertificate: GetCertificateDetailsDto;
  guide: GuideDetailsType;
  onRefetch: () => Promise<
    QueryObserverResult<GetOneCertificateDto[], unknown>
  >;
}) => {
  const { t } = useTranslation();
  const userPersonalProfile = usePersonalProfileQuery(true);
  const userFullName =
    userPersonalProfile?.data?.personalization_criteria?.full_name?.[0];

  const bottomSheetState = useToggleState();
  const { handleSuccessEnter, addNameBottomSheetState } = useCreateAndOpenCertificate();
  const { refetchCertificatesPolling } =
    usePollAndOpenCertificate(onRefetch);

  const guideProgress = useMemo(() => progress.progress * 100, [progress]);
  const isGuideCompleted = useMemo(
    () => guideProgress === 100,
    [guideProgress]
  );

  const handleCertificateClick = () => {
    GuidesV2Analytics.onViewCertificateClick();

    const userHasFullName = !!userFullName;

    if (userHasFullName) {
      bottomSheetState.handleOpen();
    } else {
      addNameBottomSheetState.handleOpen();
    }
  };

  return (
    <>
      <div className="guide-certifcate__separator">
        <img
          src={SeparatorIcon}
          alt="separator icon"
        />
      </div>
      <div className="px-4 mt-12">
        <div className="guide-certificate__container px-4 pt-4 pb-[18px]">
          <div className="guide-certificate__container__card">
            <div className="guide-certificate__container__card__inner pt-6 pb-6">
              {isGuideCompleted ? (
                <img
                  src={guideCertificate.success_image}
                  alt=""
                  className=" mx-auto mt-2"
                  width={80}
                  height={80}
                />
              ) : (
                <img
                  src={guideCertificate.blank_image}
                  alt=""
                  className=" mx-auto"
                  width={80}
                  height={80}
                />
              )}
              {isGuideCompleted && (
                <h3 className="text-lg font-bold text-center mt-4 mb-[9px]">
                  {t('guides-v2.certificate.label-complete')}
                </h3>
              )}
              {!isGuideCompleted && (
                <>
                  <h3 className="text-lg font-bold text-center mt-4">
                    {t('guides-v2.certificate.label-incomplete')}
                  </h3>
                  <div className="mt-4 w-full">
                    <div className="bg-fill-primary max-w-[220px] h-[10px] rounded-lg mx-auto" />
                    <div className="bg-fill-primary max-w-[134px] h-[10px] mt-[8px] rounded-lg mx-auto" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="guide-certificate__container__info pt-4">
            <h3 className="text-lg font-bold">
              {isGuideCompleted
                ? t('guides-v2.certificate.complete.title')
                : t('guides-v2.certificate.incomplete.title')}
            </h3>
            <p className="text-md mt-1">
              {isGuideCompleted
                ? t('guides-v2.certificate.complete.description')
                : t('guides-v2.certificate.incomplete.description')}
            </p>
            <div className="flex items-center mt-4">
              <div className="guide-certificate__container__info__progress">
                <div
                  className={classNames(
                    'guide-certificate__container__info__progress__line',
                    {
                      'guide-certificate__container__info__progress__line--green':
                        isGuideCompleted,
                    }
                  )}
                  style={{ width: `${guideProgress}%` }}
                />
              </div>
              <p className="ml-2">{guideProgress}%</p>
            </div>
            {isGuideCompleted && (
              <UiPrimaryButton
                className="mt-4"
                onClick={handleCertificateClick}
              >
                {t('guides-v2.certificate.view-certificate')}
              </UiPrimaryButton>
            )}
          </div>
        </div>
      </div>
      {guideCertificate && (
        <EnterNameBottomSheet
          onClose={addNameBottomSheetState.handleClose}
          isOpen={addNameBottomSheetState.isOpen}
          isEditing={false}
          onSuccess={() => {
            handleSuccessEnter(guideCertificate.guide_id)
          }}
          guideId={guideCertificate.guide_id}
        />
      )}
      {guideCertificate && (
        <CertificateBottomSheet
          isOpen={bottomSheetState.isOpen}
          onClose={bottomSheetState.handleClose}
          certificate={guideCertificate}
          refetch={async () => {
            bottomSheetState.handleClose();
            await refetchCertificatesPolling(guideCertificate);
          }}
        />
      )}
    </>
  );
};
