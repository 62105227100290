import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import {
  GetCertificateDetailsDto,
  GetOneCertificateDto,
} from '@/entities/certificates';
import crossIconSrc from '@/images/cross_icon.svg';
import downloadIconSrc from '@/images/download.svg';
import writeIconSrc from '@/images/write.svg';
import { useToggleState } from '@/shared/hooks/useToggleState';

import { CertificateAnalytics } from '../lib/analytics';

import { EnterNameBottomSheet } from './enter-name-bottom-sheet';

interface Props {
  isOpen: boolean;
  certificate: GetCertificateDetailsDto | GetOneCertificateDto;
  onClose: VoidFunction;
  refetch: VoidFunction;
}

export const CertificateBottomSheet: FC<Props> = ({
  isOpen,
  certificate,
  onClose,
  refetch,
}) => {
  const { t } = useTranslation();

  const editNameBottomSheetState = useToggleState();

  const handleDownload = () => {
    CertificateAnalytics.onCertificateView({
      guide_id: certificate.guide_id,
      action: 'view_certificate',
    });
    window.open(certificate.pdf_url, '_blank');
    onClose();
  };

  const handleSuccessEdit = () => {
    editNameBottomSheetState.handleClose();
    refetch();
  };

  const handleClose = () => {
    CertificateAnalytics.onCertificateView({
      guide_id: certificate.guide_id,
      action: 'close',
    });
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      CertificateAnalytics.onCertificateView({
        guide_id: certificate.guide_id,
      });
    }
  }, [certificate.guide_id, isOpen]);

  return (
    <BottomSheet
      key="select-categories-bottomsheet"
      expandOnContentDrag
      open={isOpen}
      onDismiss={handleClose}
      style={{
        height: '100%',
        zIndex: 1000000,
        position: 'relative',
      }}
    >
      <div className="my-6 mx-4">
        <header className="mb-6 flex justify-between items-center">
          <p className="font-bold text-2xl">{certificate.name}</p>
          <button className="bg-[#F5F7F9] p-1 rounded-lg" onClick={handleClose}>
            <img src={crossIconSrc} alt="Close" />
          </button>
        </header>

        <div className="border border-[#E2E5E9] p-2 bg-[#F5F7F9] rounded-lg h-[224px] mb-4">
          <div className="flex flex-col items-center justify-center w-full border border-[#E2E5E9] bg-white rounded-lg h-full">
            <img
              src={
                (certificate as GetOneCertificateDto).image ??
                (certificate as GetCertificateDetailsDto).success_image
              }
              alt="Certificate"
              className="w-[104px] mb-4"
            />
            <p className="text-s font-bold">{t('certificate.guideCompleted')}</p>
          </div>
        </div>

        <div className="mb-4">
          <p className="mb-2 text-s font-bold">{t('certificate.congratulationsTitle')}</p>
          <p className="text-s">
            {t('certificate.congratulationsDescription')}
          </p>
        </div>

        <button
          className={BUTTON_CLASSES}
          onClick={() => {
            CertificateAnalytics.onCertificateView({
              guide_id: certificate.guide_id,
              action: 'edit_name',
            });
            editNameBottomSheetState.handleOpen();
          }}
        >
          <img src={writeIconSrc} alt="edit" />
          {t('certificate.editName')}
          <ChevronRight />
        </button>
        <button className={BUTTON_CLASSES} onClick={handleDownload}>
          <img src={downloadIconSrc} alt="download" />
          {t('certificate.openCertificate')}
          <ChevronRight />
        </button>
      </div>
      <EnterNameBottomSheet
        isEditing
        onClose={editNameBottomSheetState.handleClose}
        isOpen={editNameBottomSheetState.isOpen}
        onSuccess={handleSuccessEdit}
        guideId={certificate.guide_id}
      />
    </BottomSheet>
  );
};

const BUTTON_CLASSES = 'w-full flex justify-start items-center text-s';

const ChevronRight = () => (
  <svg
    className="ml-auto"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071Z"
      fill="#6B7280"
    />
  </svg>
);
