import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { GetOneCertificateDto } from '@/entities/certificates';
import { usePersonalProfileQuery } from '@/entities/personal-profile';
import { GuideType } from '@/modules/guides/entities/guide';
import { useGuidesListQuery } from '@/modules/guides/entities/guides-list';
import { useGuidesListProgressQuery } from '@/modules/guides/entities/guides-list-progress';
import { SmallCertficateCard } from '@/modules/guides-v2/entites/small-certficate-card';
import { ArrowGuidesCards } from '@/modules/guides-v2/features/arrow-guides-cards';
import { PathDetailsHeader } from '@/modules/guides-v2/features/path-details-header';
import { getOngoingGuideByArrow } from '@/modules/guides-v2/shared/lib';

interface PathDetailsWidgetProps {
  onCertificateOpen: (certificate: GetOneCertificateDto) => void;
  onActionClick: () => void;
  className?: string;
  certificatesData: GetOneCertificateDto[];
}

export const PathDetailsWidget = ({
  onCertificateOpen,
  onActionClick,
  className, certificatesData
}: PathDetailsWidgetProps) => {
  const { t } = useTranslation();
  const navigation = useHistory();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const userArrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0] || 'ai';

  const { data: guidesListData } = useGuidesListQuery();
  const { data: guideProgressData } = useGuidesListProgressQuery();

  const filteredByArrowGuides = useMemo<GuideType[]>(() => {
    if (!guidesListData) return [];

    return guidesListData.filter(guide => guide.tags.some(tag => tag.toLowerCase() === userArrow));
  }, [guidesListData, userArrow]);

  const ongoingGuide = useMemo(
    () => getOngoingGuideByArrow({
      guides:       guidesListData,
      progressList: guideProgressData,
      withFallback: true,
      arrow:        userArrow,
    }),
    [guideProgressData, guidesListData, userArrow],
  );

  const getProgress = useCallback((guide: GuideType): number => {
    if (!guideProgressData) return 0;

    return guideProgressData[guide.id] * 100;
  }, [guideProgressData]);


  const onResumePathClick = useCallback(() => {
    if (!ongoingGuide?.guide?.id) {
      return undefined;
    }

    onActionClick();

    return navigation.push(`/guide-pathway/${ongoingGuide.guide.id}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation, ongoingGuide]);

  const onGoToGuide = useCallback((guide: GuideType) => {
    navigation.push(`/guide-pathway/${guide.id}`);
  }, [navigation]);

  return (
    <div className={className}>
      <PathDetailsHeader
        arrow={userArrow}
        onResumeClick={onResumePathClick}
      />
      <ArrowGuidesCards
        guides={filteredByArrowGuides}
        onGuideClick={onGoToGuide}
        getProgress={getProgress}
      />
      <div className="px-4 pb-5">
        <h3 className="text-xl mb-6 font-bold">{t('profile.certificates.title')}</h3>
        <div className="grid grid-cols-2 gap-4">
          {certificatesData && certificatesData.map(certificate => (
            <SmallCertficateCard
              key={certificate.guide_id}
              certificate={certificate}
              onClick={() => onCertificateOpen(certificate)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
