import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { GetOneCertificateDto } from '@/entities/certificates';
import { usePersonalProfileQuery } from '@/entities/personal-profile';
import { UiNavigation } from '@/modules/ai-bots/shared/ui';
import {
  onPathwayDetailsActionClick,
  onPathwayDetailsView,
} from '@/modules/guides-v2/entites/path-details/lib/analytics';
import { PathDetailsWidget } from '@/modules/guides-v2/widgets/path-details-widget';
import { useToggleState } from '@/shared/hooks/useToggleState';
import {
  CertificateBottomSheet,
  EnterNameBottomSheet,
  useCertificatesListQuery,
  useCreateAndOpenCertificate,
  usePollAndOpenCertificate,
} from 'src/features/certificate-open';

import Banner from './assets/png/banner.png';

export const PathDetails = () => {
  const navigation = useHistory();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const userArrow =
    userPersonalProfile?.data?.personalization_criteria?.arrow?.[0] || 'ai';

  const [certificate, setCertificate] = useState<GetOneCertificateDto>();
  const userFullName =
    userPersonalProfile?.data?.personalization_criteria?.full_name?.[0];

  const { data: certificatesListData, refetch: refetchCertificateList } =
    useCertificatesListQuery({});

  const bottomSheetState = useToggleState();
  const { handleSuccessEnter, addNameBottomSheetState } = useCreateAndOpenCertificate();
  const { refetchCertificatesPolling } =
    usePollAndOpenCertificate(refetchCertificateList);

  const handleCertificateClick = useCallback(
    (certificate: GetOneCertificateDto) => {
      if (certificate.progress < 100) {
        return;
      }

      setCertificate(certificate);
      const userHasFullName = !!userFullName;

      if (userHasFullName) {
        bottomSheetState.handleOpen();
      } else {
        addNameBottomSheetState.handleOpen();
      }
    },
    [addNameBottomSheetState, bottomSheetState, userFullName]
  );

  const onGoBack = () => {
    navigation.replace('/');
  };

  const onActionClick = () => {
    onPathwayDetailsActionClick({ name: userArrow });
  };

  useEffect(() => {
    onPathwayDetailsView({ name: userArrow });
  }, [userArrow]);

  return (
    <main className="ui-base-layout min-vh-full">
      <UiNavigation
        className="border-0"
        title=""
        onBackButtonClicked={onGoBack}
      />
      <img src={Banner} alt="banner" className="w-full" />
      <PathDetailsWidget
        onCertificateOpen={handleCertificateClick}
        onActionClick={onActionClick}
        certificatesData={certificatesListData ?? []}
      />
      {certificate && (
        <EnterNameBottomSheet
          onClose={addNameBottomSheetState.handleClose}
          isOpen={addNameBottomSheetState.isOpen}
          isEditing={false}
          onSuccess={() => handleSuccessEnter(certificate?.guide_id)}
          guideId={certificate?.guide_id}
        />
      )}
      {certificate && (
        <CertificateBottomSheet
          isOpen={bottomSheetState.isOpen}
          onClose={bottomSheetState.handleClose}
          certificate={certificate}
          refetch={async () => {
            bottomSheetState.handleClose();
            await refetchCertificatesPolling(certificate);
          }}
        />
      )}
    </main>
  );
};
