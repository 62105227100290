import { analyticsService } from '@/services/analytics.service';

export const CertificateAnalytics = {
  onCertificateView: ({
    guide_id,
    action,
  }: {
    guide_id: string;
    action?: 'edit_name' | 'view_certificate' | 'share' | 'close';
  }) => {
    analyticsService.sendEvent('webapp_certificate_modal_view', {
      action,
      guide_id,
    });
  },
  onViewEditCertificate: ({ guide_id }: { guide_id: string }) => {
    analyticsService.sendEvent('webapp_certificate_view', {
      guide_id,
      place: 'edit_name',
    });
  },
  onSubmitEdit: ({ guide_id, input }: { guide_id: string; input: string }) => {
    analyticsService.sendEvent('webapp_certificate_view', {
      place: 'edit_name',
      action: 'submit',
      input,
      guide_id,
    });
  },
};
