import { useState } from 'react';

interface ToggleStateReturn {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
}
export const useToggleState = (): ToggleStateReturn => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);


  return {isOpen, handleClose, handleOpen};
}