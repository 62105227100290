import { QueryObserverResult } from '@tanstack/react-query';

import { GetCertificateDetailsDto, GetOneCertificateDto } from '@/entities/certificates';

const MAX_RETRIES_FETCH_CERTIFICATES = 5;
const FETCH_CERTIFICATES_INTERVAL = 1000;

// После изменения user.fullName, все сертификаты перегенерируются, мы делаем long polling на сервер, пока certificate.status будет issued, а не generating
export const usePollAndOpenCertificate = (
  refetch: () => Promise<QueryObserverResult<GetOneCertificateDto[], unknown>>
) => {
  const handleSuccessGenerating = (data: GetOneCertificateDto[], certificate: GetOneCertificateDto | GetCertificateDetailsDto) => {
    const foundCertificate = data?.find(
      c => c.guide_id === certificate?.guide_id
    );
    if (foundCertificate) {
      window.open(foundCertificate.pdf_url, '_blank');
    }
  }

  const refetchCertificatesPolling = async (
    certificate: GetOneCertificateDto | GetCertificateDetailsDto,
    requestNumber = 0
  ) => {
    const { data } = await refetch();
    const hasGeneratingCertificate = !!data?.find(
      certificate => certificate.status === 'regenerating'
    );

    if(!hasGeneratingCertificate) {
      handleSuccessGenerating(data ?? [], certificate);
      return;
    }
    const requestCountExceeded = requestNumber > MAX_RETRIES_FETCH_CERTIFICATES;
    if (requestCountExceeded) {
      throw Error('CERTIFICATE_REGENERATE_AFTER_RENAME_LIMIT_EXCEEDED');
    }
    setTimeout(async () => {
      await refetchCertificatesPolling( certificate, requestNumber + 1,);
    }, FETCH_CERTIFICATES_INTERVAL);
  };

  return { refetchCertificatesPolling };
};