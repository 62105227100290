import { GetOneCertificateDto } from '@/entities/certificates';
import { ProgressIndicator } from '@/entities/progress-indicator';

interface SmallCertificateCardProps {
  certificate: GetOneCertificateDto
  onClick?: () => void;
}

export const SmallCertficateCard = ({ certificate, onClick }: SmallCertificateCardProps) => (
  <button
    type="button"
    onClick={onClick}
    className="bg-fill-secondary  border border-fill-primary rounded-lg p-2"
  >
    <div className="border-fill-primary rounded-lg bg-white rounded-lg border flex flex-col items-center py-4">
      <img
        src={certificate.image}
        alt="Guide Logo"
        className="w-16 h-auto mb-4"
      />
      <p className="mb-2">{certificate.name}</p>
      <ProgressIndicator
        isDefaultGray
        progress={certificate.progress}
      />
    </div>
  </button>
);
