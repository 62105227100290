import type { AxiosResponse } from 'axios';

import { api } from '../../services/http.service';

export interface GetOneCertificateDto {
  certificate_id: string;
  created_at: string;
  guide_id: string;
  image: string;
  name: string;
  pdf_url: string;
  progress: number;
  status: string;
}

export interface GetCertificateDetailsDto {
  blank_image: string;
  certificate_id: string;
  created_at: string;
  is_completed: boolean;
  name: string;
  pdf_url: string;
  success_image: string;
  guide_id: string;
}

export const GuideCertificateAPI = {
  getCertificate(
    guideId: string
  ): Promise<AxiosResponse<GetCertificateDetailsDto>> {
    return api.get(`/certificates/progress/${guideId}`);
  },
  createCertificate(
    guideId: string
  ): Promise<AxiosResponse<GetOneCertificateDto>> {
    return api.post(`/certificates/${guideId}`);
  },
  getCertificatesList(
    arrow: string
  ): Promise<AxiosResponse<GetOneCertificateDto[]>> {
    return api.get(`/certificates/progress?arrow=${arrow}`);
  },
};
